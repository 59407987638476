import React from "react";

const About = () => (
  <>
    <br />

    <p><strong>What is this?</strong></p>
    <p>10A's side project #005 - a personal media / anti-social media.</p>
    <br />
    <br />

    <p><strong>What's cool about it?</strong></p>
    <p>All <a href="https://dailyforages.com/">timestamped updates</a> are recorded via a text bot (built with Twilio); meaning public updates
    can be made without going on the internet. A minimal effort / social cost life log that allows you to live in your present.</p>
    <br />
    <br />
    <p><strong>Is that it?</strong></p>
    <p>More to come on weekends.</p>
  </>
);

export default About;
