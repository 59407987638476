import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./App.css";

import Home from "./pages/home";
import About from "./pages/about";
import Post from "./pages/post";
import NoMatch from "./pages/no-match";

function App() {
  return (
    <Router>
      <nav>
        <div className="nav-left">
          <Link to="/">
            <h2>Daily Forages</h2>
          </Link>
        </div>

        <div className="nav-right">
          <Link to="/about">
            <p>About</p>
          </Link>

          {/* <Link to="/">
            <p style={{color: "gray"}}>Books (coming soon)</p>
          </Link>

          <Link to="/">
            <p style={{color: "gray"}}>Photos (coming soon)</p>
          </Link> */}
        </div>
      </nav>
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route path="/404" component={NoMatch} />
          <Route path="/:slug" component={Post} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
