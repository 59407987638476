import React, { useState } from "react";
// import { Link } from "react-router-dom";

import { getFirebase } from "../firebase";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);

  if (loading && !blogPosts.length) {
    getFirebase()
      .database()
      .ref("/2021")
      .once("value")
      .then(snapshot => {
        let posts = [];
        const snapshotVal = snapshot.val();

        for (let month in snapshotVal) {
          posts.push({ month: month, monthText: snapshotVal[month] });
        }

        const newestFirst = posts.reverse();
        setBlogPosts(newestFirst);
        setLoading(false);
      });
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {blogPosts.map(blogPost => (
        <div className="month-list" key={blogPost.month}>
          {Object.entries(blogPost.monthText).map(([day, dayText]) => (
            <div className="day-list" key={blogPost.month + day}>
              <span className="title-date" key={blogPost.month + day}>
                2021/{blogPost.month}/{day}
              </span>

              {Object.entries(dayText).map(([id, textObject]) => (
                <div key={id} className="card-content">
                  <p key={id}>
                    {textObject.time} &mdash;{" "}
                    <span style={{ color: "#5e5e5e" }} key={id}>
                      {textObject.text}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          ))}
          <hr />
        </div>
      ))}
    </>
  );
};

export default Home;
